<template>
    <div class="practice-index">
        <el-form class="page-search" :inline="true">
            <div class="left">
                <div class="show">
                    <el-form-item label="">
                        <el-input
                            v-model="searchForm.titleLike"
                            placeholder="课程主题"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="">
                        <el-input
                            v-model="searchForm.courseTitleLike"
                            placeholder="课程主题"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="">
                        <el-input
                            v-model="searchForm.teachersLike"
                            placeholder="主讲人"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="">
                        <el-date-picker
                            v-model="dateRange"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开课开始时间"
                            end-placeholder="开课结束时间"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            :clearable="true"
                        >
                        </el-date-picker>
                    </el-form-item>
                </div>
            </div>
            <div class="right">
                <div class="search-btn">
                    <!-- <div class="more" @click="conceal = !conceal">
                        <span v-show="!conceal">展开</span>
                        <span v-show="conceal">收起</span>
                        <i v-show="!conceal" class="el-icon-arrow-down"></i>
                        <i v-show="conceal" class="el-icon-arrow-up"></i>
                    </div> -->
                    <div class="custom-btn">
                        <el-button
                            type="primary"
                            @click="querydata"
                        >
                            查询
                        </el-button>
                        <el-button @click="reset">重置</el-button>
                    </div>
                </div>
            </div>
        </el-form>
        <div class="page-content">
            <div class="page-handle-btn">
                <span>
                    <i class="el-icon-plus" @click="handleAdd">新增</i>
                </span>
            </div>
            <div class="items">
                <div
                    v-for="(item, index) in items"
                    :key="index"
                    class="item"
                ></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PracticeIndex',
    components: {},
    data () {
        return {
            conceal: false,
            searchForm: {},
            dateRange: [],
            data: [
                {
                    id: 8,

                    title: 'test',

                    startTime: null,

                    endTime: null,

                    version: 0,

                    tenantId: 1000,

                    enabled: true,

                    creator: '管理员',

                    updater: null,

                    createTime: '2021-05-24 19:18:27',

                    updateTime: '2021-05-24 19:18:27',

                    learningPlanCourseList: [
                        {
                            id: 4,

                            planId: 8,

                            courseId: 1,

                            courseTitle: 'Excel函数',

                            planStart: '2021-05-10 10:24:00',

                            planEnd: '2021-05-10 10:24:00',

                            courseStart: null,

                            courseEnd: null,

                            status: 0,

                            version: 0,

                            tenantId: 1000,

                            enabled: true,

                            creator: '管理员',

                            updater: null,

                            createTime: '2021-05-24 19:18:27',

                            updateTime: '2021-05-24 19:18:27',

                            learningCourse: {
                                id: 1,

                                title: 'Excel函数',

                                description: null,

                                courseDuration: 0,

                                courseScore: 1.0,

                                learningChannel: '线下',

                                signRequired: true,

                                bookRequired: false,

                                teacherId: 1016,

                                teachers: '林扬威',

                                version: 0,

                                tenantId: 1000,

                                enabled: true,

                                creator: '系统',

                                updater: '系统',

                                createTime: '2021-05-10 10:24:00',

                                updateTime: '2021-05-10 10:24:00',

                                titleLike: null,

                                teachersLike: null
                            }
                        }
                    ],

                    titleLike: null,

                    courseTitleLike: null,

                    teachersLike: null,

                    planStartFrom: null,

                    planEndTo: null,

                    learningCourseList: null
                },

                {
                    id: 9,

                    title: 'test',

                    startTime: null,

                    endTime: null,

                    version: 0,

                    tenantId: 1000,

                    enabled: true,

                    creator: '管理员',

                    updater: null,

                    createTime: '2021-05-24 19:23:20',

                    updateTime: '2021-05-24 19:23:20',

                    learningPlanCourseList: [
                        {
                            id: 5,

                            planId: 9,

                            courseId: 1,

                            courseTitle: 'Excel函数',

                            planStart: '2021-05-10 10:24:00',

                            planEnd: '2021-05-10 10:24:00',

                            courseStart: null,

                            courseEnd: null,

                            status: 0,

                            version: 0,

                            tenantId: 1000,

                            enabled: true,

                            creator: '管理员',

                            updater: null,

                            createTime: '2021-05-24 19:23:20',

                            updateTime: '2021-05-24 19:23:20',

                            learningCourse: {
                                id: 1,

                                title: 'Excel函数',

                                description: null,

                                courseDuration: 0,

                                courseScore: 1.0,

                                learningChannel: '线下',

                                signRequired: true,

                                bookRequired: false,

                                teacherId: 1016,

                                teachers: '林扬威',

                                version: 0,

                                tenantId: 1000,

                                enabled: true,

                                creator: '系统',

                                updater: '系统',

                                createTime: '2021-05-10 10:24:00',

                                updateTime: '2021-05-10 10:24:00',

                                titleLike: null,

                                teachersLike: null
                            }
                        }
                    ],

                    titleLike: null,

                    courseTitleLike: null,

                    teachersLike: null,

                    planStartFrom: null,

                    planEndTo: null,

                    learningCourseList: null
                },

                {
                    id: 10,

                    title: 'test',

                    startTime: null,

                    endTime: null,

                    version: 0,

                    tenantId: 1000,

                    enabled: true,

                    creator: '管理员',

                    updater: null,

                    createTime: '2021-05-24 19:23:21',

                    updateTime: '2021-05-24 19:23:21',

                    learningPlanCourseList: [
                        {
                            id: 6,

                            planId: 10,

                            courseId: 1,

                            courseTitle: 'Excel函数',

                            planStart: '2021-05-10 10:24:00',

                            planEnd: '2021-05-10 10:24:00',

                            courseStart: null,

                            courseEnd: null,

                            status: 0,

                            version: 0,

                            tenantId: 1000,

                            enabled: true,

                            creator: '管理员',

                            updater: null,

                            createTime: '2021-05-24 19:23:21',

                            updateTime: '2021-05-24 19:23:21',

                            learningCourse: {
                                id: 1,

                                title: 'Excel函数',

                                description: null,

                                courseDuration: 0,

                                courseScore: 1.0,

                                learningChannel: '线下',

                                signRequired: true,

                                bookRequired: false,

                                teacherId: 1016,

                                teachers: '林扬威',

                                version: 0,

                                tenantId: 1000,

                                enabled: true,

                                creator: '系统',

                                updater: '系统',

                                createTime: '2021-05-10 10:24:00',

                                updateTime: '2021-05-10 10:24:00',

                                titleLike: null,

                                teachersLike: null
                            }
                        }
                    ],

                    titleLike: null,

                    courseTitleLike: null,

                    teachersLike: null,

                    planStartFrom: null,

                    planEndTo: null,

                    learningCourseList: null
                }
            ]
        }
    },
    created () {},
    methods: {
        handleAdd () {},
        querydata () {},
        reset () {
            this.dateRange = []
            this.searchForm = {}
        }
    }
}
</script>
<style lang="less" scoped>
// @import url(); 引入公共css类
</style>
